import { WordDictionary } from "@app/shared/interfaces/word-dictionary.interface";
import { FileType } from "@app/shared/interfaces/file-type.type";
import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";

export type fileType = '';
const fileExtension: any = {
  pdf: `${ROAM_IMAGE_SVG}/bi_file-pdf.svg`,
  docx: `${ROAM_IMAGE_SVG}/ic-word.svg`,
  xlsx: `${ROAM_IMAGE_SVG}/ic-excel.svg`,
  jpeg: `${ROAM_IMAGE_SVG}/ic-image.svg`,
  jpg: `${ROAM_IMAGE_SVG}/ic-image.svg`,
  png: `${ROAM_IMAGE_SVG}/ic-image.svg`,
  folder: `${ROAM_IMAGE_SVG}/ic-file.svg`
}

const fileWordMask: WordDictionary= {
  'pdf' : 'PDF',
  'docx': 'Word',
  'xlsx': 'Excel'
}

export const fileExtensionCollection = ['pdf', 'docx', 'xlsx'];
export const imageExtensionCollection  = ['jpg', 'jpeg', 'png']
export const videoExtensionCollections = ['mp4', 'mkv']

export const getFileExtension = (type: string): string => {
  return fileWordMask[type] || type;
}

export const getFileIcon = (type: string): string => {
  return fileExtension[type] || '/assets/svg/flat-color-icons_file.svg'
}

export const getFileType = (extension: string): FileType => {
  let type:FileType = 'file';

  if(fileExtensionCollection.includes(extension)) {
    type = 'file'
  } else if(imageExtensionCollection.includes(extension)) {
    type = 'image'
  } else if(videoExtensionCollections.includes(extension)) {
    type = 'video'
  }
  return type;
}
